// https://jsbin.com/capiyoyusa/1/edit?js,console
import _ from 'lodash';
import {
    GET_ALL_PRODUCTS
} from 'src/actions/productActions';

const INITIAL_STATE = {
    allProducts: {},
    productsFetched: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS:
            return {...state, allProducts: _.mapKeys(action.payload, '_id'), productsFetched: true};
        
        // case CREATE_ORDER:
        //     if (action.payload.error) return {...state, error: action.payload.error};
        //     else {
        //         let allOrders = { ...state.allOrders };
        //         allOrders = { ...allOrders, [action.payload._id]: action.payload };
        //         return { ...state, allOrders };
        //     }

        // case UPDATE_ORDER:
        //     if (action.payload.error) return {...state, error: action.payload.error};
        //     else {
        //         let allOrders = { ...state.allOrders };
        //         allOrders = { ...allOrders, [action.payload._id]: action.payload };
        //         return { ...state, allOrders };
        //     }

        // case GET_ORDER:
        //     let allOrders = { ...state.allOrders };
        //     allOrders = { ...allOrders, [action.payload._id]: action.payload };
        //     return { ...state, allOrders };

        default:
            return state;
    }
}